<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">活动</span>
        </div>
        <div class="i-back-normal-head-right-text"></div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="invite">
        <div class="content flex-column">
          <div class="invite-banner">
            <div>邀请好友，送 VIP 会员</div>
          </div>
          <div class="flex-between" style="margin-bottom: 16px">
            <div class="i-card card-white flex-center i-segment" style="flex: 1 1 0%">
              <div class="segment-item">
                <div>
                  <span class="value">{{activity.viteCount}}</span>
                  <span class="segment-unit"> 人</span>
                </div>
                <div class="segment-text">已邀请</div>
              </div>
              <div class="segment-item">
                <div>
                  <span class="value" style="color: rgb(248, 77, 77)">{{activity.registerCount}}</span>
                  <span class="segment-unit"> 人</span>
                </div>
                <div class="segment-text">已注册</div>
              </div>
            </div>
            <div class="i-card card-white i-step" style="flex: 1 1 0%; margin-left: 16px">
              <div class="step-item">
                <div class="step-number">01</div>
                <div class="step-text">分享好友</div>
              </div>
              <div class="step-item">
                <div class="step-number">02</div>
                <div class="step-text">达成条件</div>
              </div>
              <div class="step-item">
                <div class="step-number">03</div>
                <div class="step-text">接受邀请</div>
              </div>
              <div class="step-item">
                <div class="step-number">04</div>
                <div class="step-text">兑换会员</div>
              </div>
            </div>
          </div>
          <div class="invite-footer flex-between i-card">
            <div>
              <img @click="openShowViteUrlModal" src="../../assets/static/4019fe89a4ac7bbc5e07f12f6332ca9514781ea5.png" class="share-img"/>
            </div>
            <button @click="gotoActivityDetail" type="button" class="ant-btn i-button button-white" style="min-width: 128px">
              <span>邀请详情</span>
            </button>
          </div>
        </div>
        <div class="activity-intro i-special-card">
          <div class="activity-intro-head flex-row">活动规则</div>
          <div class="activity-intro-content">
            <div class="activity-intro-item flex-row">
              <span class="flex-center">1</span>
              <span>扫码上方二维码进入财咖云插件小程序, 将小程序或活动海报分享给好友参与活动</span>
            </div>
            <div class="activity-intro-item flex-row">
              <span class="flex-center">2</span>
              <span>好友参与活动，完成注册、登录后，即可获得7天财咖云插件会员权益</span>
            </div>
            <div class="activity-intro-item flex-row">
              <span class="flex-center">3</span>
              <span>每邀请一名好友成功参与活动，分享者会员时长可同时增加7天，可累计</span>
            </div>
            <div class="activity-intro-item flex-row">
              <span class="flex-center">4</span>
              <span>会员权益在财咖云插件PC端、移动端均可使用</span>
            </div>
            <div class="activity-intro-item flex-row">
              <span class="flex-center">5</span>
              <span>活动禁止一切作弊行为，一经发现将封号处理</span>
            </div>
            <div class="activity-intro-item flex-row">
              <span class="flex-center">6</span>
              <span>活动最终解释权归本公司所有</span>
            </div>
          </div>
        </div>
      </div>
    </main>

    <CustomModal v-model="showViteUrlModal" :width="260">
        <div class="code-modal-intro" style="width: 200px;height: 200px;margin-bottom:0px;">
          <img v-show="!!inviteUrl" :src="inviteUrl" class="share-img" style="width: 200px;height: 200px;"/>
        </div>
    </CustomModal>

  </section>
</template>

<script>
export default {
  name: "activity",
  data() {
    return {
      activity: {},
      showViteUrlModal: false,
      inviteUrl: null
    }
  },
  created(){
    this.getActivityData();
  },
  methods: {
    getActivityData(){
      this.$http.post('/api/user/getActivityData').then(data=>{
        this.activity = data;
      });
    },
    gotoActivityDetail(){
      this.$router.push({name: 'activityDetail'});
    },
    openShowViteUrlModal(){
      if(!this.inviteUrl){
         this.generateViteUrl();
      }
      this.showViteUrlModal = true;
    },
    generateViteUrl(){
      this.$http.post('/api/user/generateViteUrl').then(data=>{
        this.inviteUrl = data;
      });
    }
  }
};
</script>
<style scoped>
.activity {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>